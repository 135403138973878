<template>
    <div id="bg" class="bg-antrian">
        <div id="main" class="flex justify-content-between">
        <!-- yang baru masuk -->
        <div class="container-fluid pt-2">
            <div class="row g-1">
            <div class="col-md-6">
                <div class="active h-100 m-0">
                <div class="process w-100 h-100 flex">
                    <div class="header flex py-1">
                    <div class="loket-split flex">
                        <div class="wrap_logo">
                        <img :src="assetLocal('img/logo-rsh-wide-white.png')">
                        </div>
                    </div>
                    <div class="loket-split flex">
                        <div class="date_time_wrap">
                        <p>{{ dates || getDate }}</p>
                        <h3>{{ times || getTime }}</h3>
                        </div>
                    </div>
                    </div>
                    <div class="antrian-active">
                        <div class="wrap_body_queue">
                            <p>Nomor Antrean</p>
                            <h2>{{active.ar_no_antrian||"-"}}</h2>
                        </div>
                        <div class="wrap_foot_queue">
                            <p style="font-size: 4vh;">{{active.aru_unit_tujuan||"-"}}</p>
                            
                            <template v-if="(active.audioList||[]).length">
                                <div class="elAud d-none" v-for="(v,k) in (active.audioList||[])" :key="k">
                                    <audio controls :id="'aud-'+k">
                                        <source :src="uploader(v.ara_file)" type="audio/mpeg">
                                        Your browser does not support the audio element.
                                    </audio>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div v-for="(v,k) in (data||[])" :key="k" class="col-md-6">
                <div class="queue h-auto bg-white">
                <div class="d-flex align-items-center flex-wrap bg-primary text-white px-2 py-1">
                    <h2 class="font-weight-semibold mb-0">{{v.bu_full_name||"-"}}</h2>
                    <h4 class="m-0 ml-auto">{{v.loket || 'Belum Setting Loket'}}</h4>
                </div>
                <div class="p-2">
                    <div class="form-row row-gutter">
                        <div class="col-md-6" v-for="(v1,k1) in (v.data||[])" :key="k1">
                            <div class="loket flex m-0 w-100" :style="v1.warna_unit?`background-color:${v1.warna_unit};min-height:17vh;`:'background-color:#009ACA;min-height:17vh;'">
                            <div class="header flex bg-green-haze">
                                <div class="nama-loket" style="font-size:2.75vh;">
                                <span class="kode-1">{{v1.unit_tujuan||'Nomor Antrian'}}</span>
                                </div>
                            </div>
                            <div class="nomor flex">
                                <span class="nomor-antrian" id="nomor-satu" style="font-size: 7.3vh;">{{v1.ar_no_antrian}}</span>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>

        <div class="foot flex">
            <marquee class="html-marquee" direction="left" behavior="scroll" scrollamount="12">
            <p>JAM BUKA LAYANAN KAMI ADALAH PUKUL 07:00 s.d 21.00. TERIMA KASIH ATAS KUNJUNGAN ANDA . KAMI SENANTIASA
                MELAYANI SEPENUH HATI</p>
            </marquee>
        </div>
        </div>
    </div>
</template>


<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen.js'

const moment = require('moment')
import $ from 'jquery'
import _ from 'lodash'


export default {
    extends: GlobalVue,
    data() {
        return {
            times : null,
            dates : null,
            data : [],
            active : {},
            dataAll : [],

            queue: [],
            isPlay: false,

            totalRegister: 0,
            page: 1,
            isLoad: false,
        }
    },
    mounted() {
        setInterval(()=>{
            this.getTimeF()
        },1000)

        setInterval(()=>{
            this.getDatesF()
        },1000)
        
        setInterval(()=>{
          if(!this.isPlay){
            if(this.page != this.totalpage && this.page <= this.totalpage){
              this.page = parseInt(this.page) + 1
            }else if(this.page > this.totalpage){
              this.page = 1
            }else{
              this.page = 1
            }
            this.apiGet()
          }else{
            if(this.page != this.totalpage && this.page <= this.totalpage){
              this.page = parseInt(this.page) + 1
            }else if(this.page > this.totalpage){
              this.page = 1
            }else{
              this.page = 1
            }
            this.apiGet(true)
          }
        },10000)
         
        this.apiGet()
        this.$socket.emit('new_connection',this.$route.query.users||3)

        // console.log("test heyyo")

        this.sockets.subscribe('disp_panggil_pasien', function(data) {
            if(!this.isPlay){
              this.isPlay = true
              this.notifPanggil(data)
            }else{
              this.queue.push(data)
            }
        })
        
        this.sockets.subscribe('disp_refresh_data', function(data) {
            if(!this.isPlay){
              this.apiGet()
            }
        })

        setTimeout(()=>{
          this.$swal({
            title: 'Aplikasi ini menggunakan audio sebagai media output klik untuk lanjutkan',
            icon: 'warning',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(result => {
            if (result.value) {
              this.interactAudio()
            }
          })
        },2000)
    },
    computed: {
        getTime(){
            return moment().format('HH:mm:ss')
        },
        getDate(){
            return moment().format('dddd, Do MMMM YYYY')
        },

        currentPage(){
          return this.page || 1
        },
        perpage(){
          return 3
        },
        totalpage(){
          return Math.ceil(this.totalRegister/3) || 1
        },
    },
    
    methods: {
        interactAudio(){
          let audio = new Audio(this.uploader("audio/kosong.wav"))
          let self = this
          audio.muted = true
          audio.play()  
        },
        getTimeF(){
            this.times = moment().format('HH:mm:ss')
        },
        getDatesF(){
            this.dates = moment().format('dddd, Do MMMM YYYY')
        },


        
        playAudio(key,len){
            setTimeout(()=>{
                if((this.active.audioList||[]).length){
                  console.log(this.uploader(this.active.audioList[key]['ara_file']))
                  let audio = new Audio(this.uploader(this.active.audioList[key]['ara_file']))
                  audio.play()  
                  let self = this
                  audio.onended = function() {
                    key = key + 1
                    if(key < len){
                        self.playAudio((key),len)
                    }
                    
                    if(key == len){
                        if((self.queue||[]).length){
                            self.notifPanggil(self.queue[0])
                            self.queue.splice(0, 1)
                        }else{
                            self.isPlay = false
                        }
                    }
                    if(key > len){
                        self.isPlay = false
                    }
                  }
                }
            },150)
        },
        notifPanggil(data){
            Gen.apiRestDisplay(
                "/displayPoliPerawatByRegCode?users="+(this.$route.query.users||3)+"&regCode="+data.ar_reg_code+"&group="+this.$route.query.group+"&page="+this.currentPage
            ).then(res=>{
                // percobaan
                console.log(res)
                if(this.currentPage != 1 && !(res.data.data||[]).length){
                    this.page = this.page - 1
                    this.notifPanggil(data)
                }else{
                    _.forEach(res.data, (v,k)=>{
                        this.$set(this, k, v)
                    })
                    setTimeout(()=>{
                        this.playAudio(0,(this.active.audioList||[]).length)                    
                    },250)
                }

            })
        },
        apiGet(noActive=false){
            if(this.totalpage != 1){
              this.isLoad = true
            }
            // default antrian perawat
            Gen.apiRestDisplay(
                "/displayPoliPerawat?users="+(this.$route.query.users||3)+"&group="+this.$route.query.group+"&page="+this.currentPage
            ).then(res=>{
                console.log(noActive)
                if(noActive){
                    this.data = res.data.data
                }else{
                    _.forEach(res.data, (v,k)=>{
                        this.$set(this, k, v)
                    })
                }
            })
        },
    },

    sockets: {
        connect() {
            this.$socket.emit('new_connection',this.$route.query.users||3)
            this.isConnected = true
        },

        disconnect() {
            this.isConnected = false
        },

        messageChannel(data) {
            this.socketMessage = data
        },
    },
}
</script>

<style lang="scss" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap');
    .bg-antrian #main .queue{
        min-height: 45vh !important;
    }
</style>